import React from 'react'

/**
 * In the following code we test if the cell is undefined before trying to use
 * it. If can happen when the render() happens too soon before the new mine field
 * is computed (size of the field has been increased).
 */
const Square = ({ cell, gameover, success, onSquareClick, onSquareContextMenu }) => {
  const mineIcon = '🧨'
  const flagIcon = '🚩'
  const unknownIcon = '·' // this is a middle dot

  /**
   * Display the correct icon depending on the cell state.
   * To debug mines comment "not checked yet, it's not known" and just below
   * cell.isChecked.
   */
  const icon = () => {
    // cell is not defined yet, let's put the standart icon before the new mine
    // field is computed and available
    if (cell === undefined) {
      return unknownIcon // use x to debug
    }

    // show all mines on gameover
    if (gameover && cell.hasMine) {
      return mineIcon
    }

    // on success put flags on remaining uncliked cells
    if (success && cell.hasMine) {
      return flagIcon
    }

    if (cell.isFlagged) {
      return flagIcon
    }

    // not checked yet, it's not known
    if (!cell.isChecked) {
      return unknownIcon
    }

    // it was checked but the cell has a mine (the game should be gameover)
    if (cell.isChecked && cell.hasMine) {
      return mineIcon
    }

    // otherwise show the number of mines around it (@see App::getNeighbors)
    if (cell.minesAround > 0) {
      return cell.minesAround
    }

    // otherwise is cell has been checked and there is no mine on it
    // this is the same icon for auto-discovered icons

    return ''
  }

  const className = () => {
    // Avoid errors when cell is not defined yet (refactor to do).
    if (cell === undefined) {
      return 'square'
    }

    // specific color depening on the mines around
    let colorClass = ''
    if (cell.minesAround !== 0) {
      colorClass += ' around-' + cell.minesAround
    }

    // the cell/click that has caused the failure
    let failClass = ''
    if (cell.isFailClick) {
      failClass += ' square-fail'
    }

    return 'square' + colorClass + failClass
  }

  return (
        <button className={className()}
                onClick={onSquareClick}
                onContextMenu={onSquareContextMenu}
        >
            {icon()}
        </button>
  )
}

export default Square
