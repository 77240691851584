export default class Cell {
  constructor (hasMine, index) {
    this.index = index // Current index in the mine field
    this.hasMine = hasMine // if the call has a mine or not
    this.isChecked = false // left mouse click, verify it is not a mine
    this.minesAround = 0 // mines around: 0 to 8
    this.isFlagged = false // right mouse click, mark the cell has having a mine
    this.isFailClick = false // mark the cell that has caused the gameover
  }

  /**
     * Test if a left click can be done on the cell.
     */
  isLeftClickable () {
    if (this.isChecked) {
      return false
    }

    return !this.isFlagged
  }

  /**
     * Test if a right click can be done on the cell.
     */
  isRightClickable () {
    return !this.isChecked
  }

  /**
     * Test if the cell was left of righ clicked.
     */
  isClicked () {
    return this.isChecked || this.isFlagged
  }
}
