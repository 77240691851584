// react
import React, { useState, useEffect } from 'react'

// app
import logo from './logo.svg'
import './App.css'
import Cell from './types/Cell'
import Square from './components/Square'

function App () {
  // stores if the game is a failure (a mine was left cliked)
  const [gameover, setGameover] = useState(false)

  // stores if the game is terminated and is a success
  const [success, setSuccess] = useState(false)

  // percentage of mines in the field (12%)
  const [minesPercentage, setMinesPercentage] = useState(12)

  // the field width (horizintal)
  const [cols, setCols] = useState(9)

  // the field height (vertical)
  const [rows, setRows] = useState(9)

  // the array containing all cells
  const [minefield, setMinefield] = useState(generateMinesField())

  // if the game has started, a game is started after the first click
  const [started, setStarted] = useState(false)

  // timer in seconds
  const [timer, setTimer] = useState(0)

  const [flagMode, setFlagMode] = useState(false)

  // update the mine field when the size changes
  useEffect(() => {
    reset()
  }, [cols, rows])

  // update the timer
  useEffect(() => {
    let interval = null
    if (started) {
      interval = setInterval(() => {
        setTimer(timer => timer + 1)
      }, 1000)
    } else if (!started && timer !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [started, timer])

  useEffect(() => {
    // Ajouter un écouteur d'événement lorsque le composant est monté
    document.addEventListener('keydown', handleKeyPress)

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, []) // Les crochets vides assurent que cet effet n'est exécuté qu'une seule fois à la création du composant

  const handleKeyPress = (event) => {
    event.preventDefault()
    // historically the F2 is always bound to restart
    if (event.key === 'F2') {
      reset()
      return
    }
    if (event.key === 'F3') {
      baby()
      return
    }
    if (event.key === 'F4') {
      beginner()
      return
    }
    if (event.key === 'F5') {
      intermediate()
      return
    }
    if (event.key === 'F6') {
      expert()
      return
    }
    if (event.key === 'F7') {
      hell()
    }
  }

  /**
   * Reset and start a new game.
   */
  function reset () {
    setMinefield(generateMinesField())
    setTimer(0)
    setGameover(false)
    setStarted(false)
    setSuccess(false)
    setFlagMode(false)
  }

  function switchFlagMode () {
    setFlagMode(!flagMode)
  }

  function beginner () {
    setMinesPercentage(12)
    setRows(9)
    setCols(9)
    reset()
  }

  function baby () {
    setMinesPercentage(9)
    setRows(5)
    setCols(5)
    reset()
  }

  function intermediate () {
    setMinesPercentage(15)
    setRows(16)
    setCols(16)
    reset()
  }

  function expert () {
    setMinesPercentage(20)
    setRows(16)
    setCols(30)
    reset()
  }

  function hell () {
    setMinesPercentage(26)
    setRows(36)
    setCols(36)
    reset()
  }

  function start () {
    if (started) {
      return
    }
    setStarted(true)
  }

  function stop () {
    setStarted(false)
  }

  /**
   * Handle left click on cell: discover real cell state!
   */
  function handleClick (i) {
    if (flagMode) {
      return handleContextMenu(i)
    }

    // the game must be reset to replay
    if (gameover || success) {
      return
    }

    start() // the game starts at the first left click

    const newMinefield = minefield.slice()
    const cell = newMinefield[i]
    if (!cell.isLeftClickable()) {
      return
    }

    // check if there is a mine!
    cell.isChecked = true
    if (cell.hasMine) {
      cell.isFailClick = true
      setGameover(true)
      stop()
      return
    }

    // compute the mines around
    const neighbors = getNeighbors(newMinefield, i)
    cell.minesAround = neighbors.reduce((carry, cell) =>
      carry + (cell.hasMine ? 1 : 0)
    , 0)
    setMinefield(newMinefield)

    // If there is no mine around, that means that we can safely auto-click these
    // cells for the user to speed up the sweep process
    if (cell.minesAround === 0) {
      neighbors.forEach(function (item) {
        handleClick(item.index)
      })
    }

    checkSuccess()
  }

  /**
   * Check if the game is over and a success.
   */
  function checkSuccess () {
    if (
    // The cells not clicked are all unflagged mines
      (minesCount() - flaggedMinesCount()) === cellsNotClicked() &&
        // AND all flagged mines are correct
        (wrongFlaggedMinesCount() === 0)
    ) {
      setSuccess(true)
      stop() // this could be in a use effect function
    }

    // Or there is exactly a flag on all mines without extra flags and all other cells are clicked (No Flag mode)
    if (minesCount() === minesFlagged() && (cellsClicked() === minefield.length)) {
      setSuccess(true)
      stop() // this could be in a use effect function
    }
  }

  /**
   * This function was generated by ChatGPT.
   */
  function getNeighbors (array, index) {
    const row = Math.floor(index / cols)
    const col = index % cols
    const neighbors = []

    // Vérifier les indices des voisins dans les 8 directions possibles
    const directions = [
      { row: -1, col: -1 }, // Diagonale supérieure gauche
      { row: -1, col: 0 }, // Haut
      { row: -1, col: 1 }, // Diagonale supérieure droite
      { row: 0, col: -1 }, // Gauche
      { row: 0, col: 1 }, // Droite
      { row: 1, col: -1 }, // Diagonale inférieure gauche
      { row: 1, col: 0 }, // Bas
      { row: 1, col: 1 } // Diagonale inférieure droite
    ]

    for (const direction of directions) {
      const newRow = row + direction.row
      const newCol = col + direction.col

      // Vérifier si les indices sont valides
      if (newRow >= 0 && newRow < rows && newCol >= 0 && newCol < cols) {
        const newIndex = newRow * cols + newCol
        neighbors.push(array[newIndex])
      }
    }

    return neighbors
  }

  /**
   * Handle right click on cell: put a flag on the cell. This click can be undone
   * unlike the left one.
   */
  function handleContextMenu (i, e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    // the game must be reset to replay
    if (gameover || success) {
      return
    }

    // The timer is not started on right click in most mine implementations

    const newMinefield = minefield.slice()
    const cell = newMinefield[i]
    if (!cell.isRightClickable()) {
      return
    }

    cell.isFlagged = !cell.isFlagged
    setMinefield(newMinefield)

    checkSuccess()
  }

  /**
   * Generate the minefield (an array of Cells).
   */
  function generateMinesField () {
    // create the matrix
    const arr = Array(rows * cols).fill(null)

    // and fill with cells with a random mine or not
    const field = arr.map((value, index) =>
      new Cell(Math.random() < (minesPercentage / 100), index)
    )

    // enforce we have at least a mine!
    const count = field.reduce((carry, cell) =>
      carry + (cell.hasMine ? 1 : 0)
    , 0)
    if (count === 0) {
      return generateMinesField()
    }

    return field
  }

  /**
   * Total number of mines the field has.
   */
  function minesCount () {
    return minefield.reduce((carry, cell) =>
      carry + (cell.hasMine ? 1 : 0)
    , 0)
  }

  /**
   * Effective mines percentage of field.
   */
  function minesEffectivePercentage () {
    let effectivePercentage = (minesCount() / (rows * cols) * 100).toFixed(2)
    if (effectivePercentage % 1 === 0) {
      effectivePercentage = parseInt(effectivePercentage)
    }

    return effectivePercentage
  }

  /**
   * Total number of correctly flagged mines.
   */
  function flaggedMinesCount () {
    return minefield.reduce((carry, cell) =>
      carry + (cell.hasMine && cell.isFlagged ? 1 : 0)
    , 0)
  }

  /**
   * Total number of wrongly flagged mines.
   */
  function wrongFlaggedMinesCount () {
    return minefield.reduce((carry, cell) =>
      carry + (!cell.hasMine && cell.isFlagged ? 1 : 0)
    , 0)
  }

  /**
   * Total number of flagged cells by the user. Be careful, it can have a mine or
   * not! Don't trust the user.
   */
  function minesFlagged () {
    return minefield.reduce((carry, cell) =>
      carry + (cell.isFlagged ? 1 : 0)
    , 0)
  }

  /**
   * A cell was "checked" if it was left or right clicked, only the right click
   * can be canceled.
   */
  function cellsClicked () {
    return minefield.reduce((carry, cell) =>
      carry + (cell.isClicked() ? 1 : 0)
    , 0)
  }

  /**
   * Cell that is no clicked, not cleared and without flag on it.
   */
  function cellsNotClicked () {
    return minefield.reduce((carry, cell) =>
      carry + (cell.isClicked() ? 0 : 1)
    , 0)
  }

  /**
   * Compute the left mines corresponding to the flags put by the user, not the real
   * count. We subtract the flags from the real mines count, so yes it can be negative.
   */
  function minesLeft () {
    return minesCount() - minesFlagged()
  }

  /**
   * Returns an array that corresponds to one row of the grid. For now the height
   * is equal to the width.
   */
  function rowArray () {
    return Array(rows).fill(null)
  }

  function columnArray () {
    return Array(cols).fill(null)
  }

  function onMinesPercentageChange (e) {
    setMinesPercentage(parseInt(e.target.value))
  }

  function onColsChange (e) {
    setCols(parseInt(e.target.value))
  }

  function onRowsChange (e) {
    setRows(parseInt(e.target.value))
  }

  /**
   * Get a given cell from the row and column number.
   */
  function getCell (row, column) {
    const idx = getCellIndex(row, column)

    return minefield[idx]
  }

  /**
   * Get the cell index in the mine array for a given row and column.
   */
  function getCellIndex (row, column) {
    return (row * cols) + column
  }

  // to improve, check https://react.dev/learn/rendering-lists

  return (
      <>
        <h1>Minesweeper by <a target="_blank" href="https://www.strangebuzz.com"
                              rel="noreferrer">COil</a></h1>
        <img src={logo} className="App-logo" alt="Minesweeper by COil"
             width="100px"/>
        <br/>
        <div>
          <div>
            Mines percentage ({minesPercentage}%):<br/>
            <input type="range" min="1" max="99" value={minesPercentage}
                   onChange={e => onMinesPercentageChange(e)}/>
          </div>
          <br/>

          <div>
            Field width ({cols}):<br/>
            <input type="range" min="1" max="50" value={cols}
                   onChange={e => onColsChange(e)}/>
            <br/>
            Field height ({rows}):<br/>
            <input type="range" min="1" max="50" value={rows}
                   onChange={e => onRowsChange(e)}/>
          </div>
          <br/>

          <button onClick={baby}>Baby</button>
          &nbsp;

          <button onClick={beginner}>Beginner</button>
          &nbsp;

          <button onClick={intermediate}>Intermediate</button>
          &nbsp;

          <button onClick={expert}>Expert</button>
          &nbsp;

          <button onClick={hell}>Hell</button>
          &nbsp;

          <br/><br/>

          <button onClick={reset} className="smiley">
            {gameover &&
                <span>😞💥</span>
            }
            {!gameover && !success &&
                <span>🙂</span>
            }
            {success &&
                <span>😎 🎉 </span>
            }
          </button>
          &nbsp;

          {!gameover && !success && started &&
              <button onClick={switchFlagMode}
                      className={'smiley' + (flagMode ? '' : ' no-flag-mode')}>🚩</button>
          }
          &nbsp;

          {(started || gameover || success) &&
              <button className="timer">
                {timer}
              </button>
          }
        </div>
        <br/>

        {rowArray().map((square, row) => (
            <div className="board-row" key={row}>
              {columnArray().map((cell, column) => (
                  <Square
                      key={getCellIndex(row, column)}
                      cell={getCell(row, column)}
                      gameover={gameover}
                      success={success}
                      onSquareClick={() => handleClick(getCellIndex(row, column))}
                      onSquareContextMenu={(e) => handleContextMenu(getCellIndex(row, column), e)}
                  />
              ))}
            </div>
        ))}

        <ul>
          <li>Time: <b>{timer}</b></li>
          <li>Mines: <b>{minesCount()}</b></li>
          <li>Mines percentage: <b>{minesEffectivePercentage()}%</b></li>
          <li>Mines left: <b>{minesLeft()}</b></li>
          <li>Mines flagged: <b>{minesFlagged()}</b></li>
        </ul>

        <ul>
          <li>Started: <b>{started ? '✅' : '❌' }</b></li>
          <li>Success: <b>{success ? '✅' : '❌' }</b></li>
          <li>Gameover: <b>{gameover ? '✅' : '❌' }</b></li>
          <li>Correctly flagged mines: <b>{flaggedMinesCount()}</b></li>
          <li>Wrong flagged mines: <b>{wrongFlaggedMinesCount()}</b></li>
          <li>Cells clicked: <b>{cellsClicked()}</b></li>
          <li>Cells not clicked: <b>{cellsNotClicked()}</b></li>
        </ul>

        <p><b>Help:</b></p>
        <ul>
          <li>Click 🚩to switch the flag mode for the left click (mobile)</li>
          <li>Click 😞, 🙂 , 😎 or <kbd>F2</kbd> to start a new game</li>
          <li><kbd>F3</kbd>: start a baby game</li>
          <li><kbd>F4</kbd>: start a beginner game</li>
          <li><kbd>F5</kbd>: start an intermediate game</li>
          <li><kbd>F6</kbd>: start an expert game</li>
          <li><kbd>F7</kbd>: start the fucking hell game</li>
          <li>Timer starts at first click</li>
          <li>No ads, no tracking. 🙏</li>
        </ul>
      </>
  )
}

export default App
